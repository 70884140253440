export default {
  VEHICLES_LIST: "Liste des véhicules",
  ADD_VEHICLE: "Ajouter un véhicule",
  VEHICLE_ADDED: "Véhicule ajouté",
  EDIT_VEHICLE: "Modifier le véhicule",
  VEHICLE_UPDATED: "Véhicule mis à jour",
  DELETE_THIS_VEHICLE: "Supprimer ce véhicule?",
  VEHICLE_DELETED: "Véhicule supprimé",
  VIN: "NIV",
  MAKE: "Marque",
  MODEL: "Modèle",
  SERIES: "# série",
  MODEL_YEAR: "Année",
  STYLE: "Style",
  ENGINE_DESCRIPTION: "Moteur",
  FUEL_TYPE: "Essence",
  TRANSMISSION: "Transmission",
  DRIVETRAIN: "Motricité",
  CLASS_CODE: "Code Type de véhicule",
  CLASS_NAME: "Type",
  VIEW_VEHICLE: "Véhicule",
  VEHICLE_AND_VIN: "Véhicule et NIV",
  SEARCH_BY_VIN: "Trouver par NIV",
  SKU: "Stock",
  REGISTRATION_NUMBER: "Plaque",
  ODOMETER: "Odomètre",
  ODOMETER_UNIT: "Unité de l’odomètre",
  COLOR: "Couleur",
  VEHICLE_IDENTIFICATION: "Identification du véhicule",
  PICTURES: "Photos du véhicule",

  MAKE_VEHICLE_ESTIMATION: "Faire une estimation de véhicule",
  ESTIMATE_VEHICLE: "Estimation d'un véhicule",

  KMS: "Kilométrage",
  KMS_RATIO: "Kilométrage ratio",
  ESTIMATE: "Estimation",
  KMS_MIN: "Kilométrage min",
  KMS_MAX: "Kilométrage max",
  KMS_AVG: "Kilométrage moy",
  KMS_AVG_RATIO: "Kilométrage moy ratio",
  KMS_AVG_MIN: "Kilométrage moy min",
  KMS_AVG_MAX: "Kilométrage moy max",
  ESTIMATE_SOLD: "Prix de l'estimation",
  ESTIMATE_SOLD_ACTIVE: "Prix courant",
  ESTIMATE_SOLD_ESS: "Estimation ESS",

  VEHICLE_ESTIMATE: "Estimer",

  AUTOMATIC_TRANSMISSION: "Automatique",
  MANUAL_TRANSMISSION: "Manuelle",

  FORBIDDEN_ERRORS: {
    CREATE: "Vous n’avez pas la permission d’ajouter un véhicule",
    UPDATE: "Vous n’avez pas la permission de modifier le véhicule",
    VIEW: "Vous n’avez pas la permission de voir les détails du véhicule",
  },
  SEARCH_BY_SKU: "Trouver par # stock",
  SKU_NUMBER: "Numéro de stock",
  QUICK_REQUEST: "Demande rapide",
  DEALER: "Concessionnaire",
  SELECT_ALL: "Tout sélectionner",
  SELECT_DEALER: "Selectionner un concessionnaire",
  DEPARTMENT: "Dépt.",
  MECHANICAL: "Mécanique",
  VEHICLE_IDENTIFICATION: "Identification du véhicule",
};
